@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  main {
    @apply max-h-screen flex flex-col flex-grow justify-center items-center py-8;
    height: 100vh;
  }

  h1 {
    @apply text-3xl font-bold mb-6;
  }
}

html,
body {
  height: 100%;
}

#root {
  min-height: 100vh;
}
